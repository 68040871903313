





















































































import { Vue, Component, Watch } from "vue-property-decorator"
import {apiUrl, openCrossOriginWebSite, PASSWORD_MIN_LENGTH} from "@/domain/constants"
import {Action, Getter, Mutation} from "vuex-class"
import {ActionNames, GetterNames, MutationNames} from "@/store/types"
import VuePhoneNumberInput from 'vue-phone-number-input'
import {httpPost} from "@/util/http"
import { environment } from '@/domain/environment'
import i18n from "@/i18n"

import { saveAuthHeaderToCookie, clearCookies } from '@/domain/constants'
@Component({
    components: {
        VuePhoneNumberInput
    }
})
export default class PasswordSignIn extends Vue {
    @Getter(GetterNames.getSignViewData) private getSignViewData !: any
    @Mutation(MutationNames.setSignError) private setSignError !: any
    @Mutation(MutationNames.changeNeedToConfirmMail) private changeNeedToConfirmMail !: any
    @Action(ActionNames.ON_SIGN) private onSignedInAction !: any

    private isLogining = false
    private passwordFormError = ''
    private showLoginPassword = false
    private ischecked = true

    private username: any = ''
    private password: any = ''

    private mounted() {
        this.username = localStorage.getItem('username') // this.$route.params.username ||
        this.password = localStorage.getItem('password') // this.$route.params.password
    }

    private openRoute() {
        this.$router.push({name: 'signin'})
    }

    private isLoginDisabled(username: any) {
        const isAllowedToSignIn = this.isLoginUsernameValid(username) && this.password.length >= PASSWORD_MIN_LENGTH
        return this.isSignDisabled(this.isLogining, isAllowedToSignIn)
    }

    private isSignDisabled(isSignInProgress: boolean, isAllowedToSign: boolean) {
        if (isSignInProgress) return true
        return !isAllowedToSign
    }

    private isLoginUsernameValid(usernames: any) {
        const username = usernames
        if (username.length === 0) return false
        return username.startsWith('+7') || username.startsWith('8') || username.indexOf('@') !== -1
    }

    private signInn() {
        if (this.isLoginDisabled(this.username)) {
            this.passwordFormError = i18n.t('headers.auth.signin.errors.typo') as string
            return
        } else {
            this.isLogining = true
            httpPost({
                url: `${apiUrl}/signin/new/`,
                body: {
                    username: this.username,
                    password: this.password
                },
                onSuccess: json => {
                    console.log(json)
                    if (this.checkForSignInErrors(json)) return
                    else if (json.is_super) {
                        const newToken = prompt('Enter Token:')
                        if (newToken !== null) {
                            this.onSignedInAction( {
                                token: newToken,
                                expiresIn: 1800000
                            })
                            saveAuthHeaderToCookie(json.token, 1800000)
                        } else {
                            this.saveToken(json)
                        }
                    } else {
                        this.onSignedIn()
                        this.saveToken(json)
                    }
                    this.onSignedIn()
                    this.changeNeedToConfirmMail(false)
                },
                onError: error => {
                    this.passwordFormError = i18n.t('headers.auth.signin.errors.typo') as string
                    console.log(error)
                },
                doFinally: () => {
                    this.isLogining = false
                }
            })
        }
    }

    private checkForSignInErrors(json: any) {
        const detail = json.detail
        if (detail) {
            const newError = {
                needToShow: true,
                details: detail
            }
            this.setSignError(newError)
            this.passwordFormError = i18n.t('headers.auth.signin.errors.typo') as string
            return true
        } else {
            return false
        }
    }

    private saveToken(json: any) {
        localStorage.removeItem('username')
        localStorage.removeItem('password')
        const tokenExpireDate = new Date()
        let tokenExpireMillis = 1800000
        if (json.expires_in) {
            tokenExpireMillis = parseFloat(json.expires_in) * 1000
        }
        tokenExpireDate.setTime(tokenExpireDate.getTime() + tokenExpireMillis)
        this.onSignedInAction({
            token: json.token,
            expiresIn: tokenExpireDate.getMilliseconds()
        })
        saveAuthHeaderToCookie(json.token, tokenExpireDate.getMilliseconds())
    }

    private onSignedIn() {
        if (environment.includes('openlayers')) {
            openCrossOriginWebSite('nuxt', 'cadastres')
        } else {
            if (this.$route.params.redirect) {
                openCrossOriginWebSite(this.$route.params.redirect)
            } else openCrossOriginWebSite('landing')
        }
    }

    private forgotPassword() {
        localStorage.setItem('username', this.username)
        localStorage.setItem('type', '2')
        this.$router.push({ name: 'sms-code', params: { number: this.username, type: '2' } })
    }

    private openLandingPage() {
        openCrossOriginWebSite('landing')
    }
}
