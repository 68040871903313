export default {
    phrases: {
        no_data: "Нет данных",
        for: "За",
        new: "новинка",
        yes: "Да",
        no: "Нет",
        answered: "Отвечено",
        not_answered: "Не отвечено",
        accept: "Принять",
        decline: "Отказать",
        search: "Поиск",
        t: " t,",
        unknown: "Неизвестно",
        your: "Напишите свой",
        error: "Ошибка"
    },
    not_chosen: "Не выбрано",
    errors: {
        nothing_found: "Ничего не найдено",
        number: "Введите номер телефона",
        data: "Данные не поддерживаются системой",
        no_seasons: "Нет выбранных сезонов",
        no_corresponding_season: "Подходящего сезона нету",
        farm_exists: "Хозяйство с таким названием уже существует!",
        required_field: "Это обязательное поле.",
        password_min_len: "Минимальная длина пароля - 6.",
        passwords_dont_match: "Пароли не совпадают.",
        incorrect_num: "Неправильный формат номера телефона",
        incorrect_email: "Неправильный формат почты",
        password_six: "Пароль должен содержать больше 6 символов",
        field_required_to_fill: "Поле обязательно для заполнения",
        no_permission: "У вас нет разрешения на доступ",
        no_description: "Описание отсуствует",
        no_info: "Информация отсутствует",
        type_season: "Введите сезон",
        incorrect_season: "Неправильно введен сезон",
        incorrect_yield: "Ошибка при вводе урожайности",
        already_exists_history: "История на этот сезон уже существует",
        field_name: "Введите наименование поля",
        already_exists_field: "Клетка с таким именем существует",
        vertices_outside: "Координаты не в клетке",
        not_enough_data: "Недостаточно данных для обработки",
        no_tasks: "У вас отсуствуют задачи по технике",
        no_cadastres: "Клетки отсутствуют",
        no_warehouse: "Склад не выбран",
        all_problems: "Заполните все поля для проблем",
        negative_area: "Площадь не может быть минусовым",
        eleven_digit_cadastre: "Кадастровый номер должен быть 11 значным числом"
    },
    success: {
        changes_saved: "Изменения сохранены",
        data_changed: "Данные изменены",
        profile_data_changed: "Данные профиля успешно изменены",
        note_deleted: "Заметка удалена",
        passport_updated: "Паспорт успешно обновлен | Паспорт успешно обновлен. Выберите клетку чтобы смотреть детали",
        deleted: "Удалено",
        history_added: "История добавлена",
        note_added: "Заметка добавлена",
        reply_added: "Ответ добавлен",
        note_updated: "Заметка обновлена",
        event_deleted: "Мероприятие удалено",
        farm_deleted: "Хозяйство удалено",
        season_updated: "Сезон успешно обновлен",
        season_added: "Сезон добавлен",
        device_updated: "Агрегат обновлен",
        device_added: "Агрегат добавлен",
        account_found: "Аккаунт найден",
        farm_added: "Хозяйство успешно добавлено | Хозяйство Совхоз успешно добавлены. Выберите хозяйство чтобы смотреть детали",
        successfully_added: " успешно добавлено",
        successfully_updated: "Успешно обновлено",
        connection: "Соединение установлено",
        staff_added: "Сотрудник добавлен",
        project_updated: "Проект успешно обновлен"
    },
    questions: {
        delete: {
            title: "Вы уверены что хотите удалить?",
            farm: "Вы действительно хотите удалить хозяйство?",
            note: "Вы уверены что хотите удалить заметку?",
            device: "Вы уверены, что хотите удалить агрегат?",
            record: "Вы уверены, что хотите удалить запись?",
            history: "Вы уверены, что хотите удалить историю?",
            events: "Хотите удалить все выбранные мероприятия?",
            event: "Хотите удалить это мероприятие?",
            staff: "Вы уверены, что хотите удалить сотрудника?",
            storage: "Вы уверены, что хотите удалить склад?"

        },
        found: {
            variety: "не нашли сорт?"
        }
    },
    instructions: {
        smth: "Напишите что-нибудь",
        note_text: "Заполните текст заметки",
        event_name: "Заполните название мероприятия",
        event_date: "Заполните дату мероприятия",
        event_cost: "Заполните стоимость мероприятия",
        farm_name: "Введите название хозяйства",
        width: "Введите ширину охвата",
        storage_name: "Введите название склада",
        machine_class: "Заполните класс техники",
        machine_model: "Заполните модель техники",
        machine_state_num: "Заполните госномер техники",
        whatever: "Можете не заполнять",
        machine_name: "Напишите госномер",
        machine_imei: "Напишите IMEI",
        machine_capacity: "Напишите вместимость бака",
        machine_consumption: "Напишите расход топлива",
        passport: "Заполнить паспорт",
        number: "Введите число",
        add_title: "Добавьте наименование",
        type_amount: "Укажите количество",
        type_cost: "Укажите стоимость",
        type_name: "Введите название",
        type_value: "Введите значение",
        finish_editing: "Нажмите 'Завершить редактирование', чтобы сохранить изменения",
        processing: "Ваш запрос находится в обработке",
        fill_form: "Заполните форму внесения",
        access_from_admins: "Для доступа отправьте запрос администратору",
        close_n_try_again: "Закройте проект и повторите попытку.",
        max_photo: "Можно загрузить максимум 10 фото",
        request_processing: "Ваш запрос находится в обработке",
        select_crop: "Для облегчения работы с задачами вы можете выбрать один из восьми ниже перечисленных культур или написать свое.",
        email: "Введите email",
        employee_name: "Введите имя сотрудника",
        employee_surname: "Введите фамилию сотрудника",
        phone: "Введите телефон номер",
        upload_file: "Загрузите файл",
        type_or_choose_event: "Выберите мероприятие",
        subscription: {
            title: "Оформите подписку",
            content: "Закажите новые данные чтобы анализировать показатели вашего поля на данный момент"
        },
        season: {
            title: "Выберите сезон",
            content: " Выберите сезон для ваших клеток, это помогает Вам организовать данные за несколько сезонов и Важно для получения данных по Вашим полям "
        },
        farm_divided: {
            title: "Ваше хозяйство разделено на клетки",
            content: "Разделение клеток упрошает управление вашим хозяйством, все дальнейшие возможности находятся внутри клеток"
        },
        rating: {
            title: "Рейтинг ваших клеток",
            content: "Рейтинг для того чтобы вы знали какая клетка показывает хороший результат либо наоборот или посмотрите какая клетка самая лучшая и т.п."
        },
        order_agrochem: {
            title: "Закажите агрохим анализ на хозяйство",
            content: "Вы также можете сделать на все хозяйство агрохим анлализ у наших партнеров. Подробнее вы можете узнать перейдя по разделу"
        },
        cadastre_edit: {
            edit: {
                1: "Выберите клетку для редактирования"
            },
            draw: {
                1: "Обозначьте вершины границ клетки, чтобы создать новую клетку",
                2: "Редактируйте и сохраните новую клетку"
            },
            delete: {
                1: "Выберите клетку для удаления",
                2: "Нажмите \"Cохранить\", чтобы завершить удаление"
            },
            merge: {
                1: "Выберите две клетки для объединения",
                2: "Нажмите \"Cохранить\", чтобы завершить объединение"
            },
            split: {
                1: "Нарисуйте линию для разделения",
                2: "Нажмите \"Cохранить\", чтобы завершить разделение"
            },
            vertix: {
                1: "Выберите клетку, в которой надо удалить точку",
                2: "Зажмите кнопку Alt на клавиатуре и выберите вершину для удаления"
            },
            vertices: {
                "1": "Выберите клетку, в которой надо удалить точки",
                "1_5": "Нарисуйте область содержащую точки для удаления",
                "2": "Нажмите \"Cохранить\", чтобы завершить удаление точек"
            },
            hole: {
                "1": "Выберите клетку, в которой хотите создать дыру",
                "1_5": "Нарисуйте дыру в клетке",
                "2": "Нажмите \"Cохранить\", чтобы завершить изменение"
            },
            info: {
                1: "Наведите курсор мыши на объект, о которой хотите получить информацию"
            }
        }
    },
    selections: {
        all: "Выбрать все",
        title: "Выберите наименование",
        farm: "Выберите хозяйство",
        field: "Выберите клетки",
        device: "Выберите название агрегата",
        class: "Выберите марку",
        model: "Выберите модель",
        season: "Выберите сезон",
        season_or_year: "Выберите сезон ИЛИ напишите год",
        income_type: "Выберите тип прихода",
        access_type: "Выберите тип доступа",
        supplier: "Введите поставщика",
        staff: "Выберите сотрудника",
        event: "Выберите мероприятие",
        expense_type: "Выберите тип расхода",
        storage: "Выберите склад",
        storage_type: "Выберите тип склада",
        equipment: "Выберите технику",
        customer_fullname: "Введите ФИО покупателя",
        scaling: "Выберите единицу измерения",
        operation: "Выберите операцию",
        all_fields: "Все клетки",
        all_farms: "Все хозяйство",
        cadastre: "Выберите кадастр",
        phenophase: "Выберите фенофазу",
        problem: "Выберите проблему заметки",
        date: "Выберите дату объезда",
        date_title: "Выберите дату",
        machine: "Выберите технику",
        device_select: "Выберите агрегат",
        category: "Выберите категорию",
        ready: "Выберите из готовых или напишите свое",
        crop: {
            title: "Выберите культуру",
            crops: "Культуры",
            options: ["Все культуры"],
            growing_crop: "Выбрать выращиваемую культуру",
            or_type: "Выберите культуру или напишите свое"
        },
        place: "Выбрать регион, город, село"
    },
    telematics: {
        speed_exceed: "Скорость превышена!"
    },
    confirm: {
        delete: {
            photo: "Фото будет удалено безвозвратно"
        },
        copy: {
            tasks: "Вы действительно хотите скопировать задачи на другую клетку?"
        },
        exit: {
            not_saved: "Вы уверены что хотите выйти? Данные не будут сохранены"
        },
        project: {
            accept: "Вы уверены, что хотите принять проект?",
            decline: "Вы уверены, что хотите отказаться от проекта?",
            delete: "Вы уверены, что хотите удалить проект?"
        }
    }
}