export default {
    title: "Тіркеу",
    login: "Жалғастыру үшін деректерді енгізіңіз, немесе | Кіріңіз",
    name: "Аты",
    surname: "Тегі",
    password: "Кіру құпиясөзі",
    passwordrepeat: "Құпиясөзді растау",
    agreement: " Түймесін басу арқылы | Сіз 'Пайдаланушы нұсқаулғы' келісесіз|",
    phone: {
        countrySelectorLabel: "Ел коды",
        countrySelectorError: "Таңдалмаған",
        phoneNumberLabel: "Телефон нөмірі",
        example: "Мысал: "
    }
}