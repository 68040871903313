export default {
    auth: {
        login0: "Кіру",
        login: "Кіру / Тіркелу",
        login_google: "Google арқылы кіру",
        sign_up: "Тіркелу",
        sign_up_free: "Тегін тіркелу",
        log_out: "Шығу"
    },
    save: {
        general: "Сақтау",
        machine: "Техниканы сақтау",
        farm: "Шаруашылықты сақтау"
    },
    actions: {
        title: "Әрекеттер",
        access: "Қолжетімділік",
        change: "Өзгерту",
        delete: "Өшіру",
        download: "PDF жүктеу",
        print: "Басып шығару",
        add: "Қосу",
        end: "Аяқтау",
        edit: "Редакциялау",
        upload: "Жүктеу"
    },
    update: "Жаңарту",
    create: "Құру",
    continue: "Жалғастыру",
    apply: "Қолдану;",
    learn_more: "Толығырақ",
    watch_video: "Видео көру",
    go_back: "Артқа",
    goto_channel: "Арнаға өту",
    go_on: "Әрі қарай",
    skip: "Өткізу",
    steps: {
        prev: "Алдыңғы",
        next: "Келесі"
    },
    new_order: "Жаңа тапсырыс",
    cancel: "Болдырмау",
    cancel_division: "Бөлуді болдырмау",
    recover: "Қалпына келтіру",
    ok: "Жақсы",
    exit: "Шығу",
    add: {
        general: "Қосу",
        staff: "Қызметкерді қосу",
        machine: "Техниканы қосу",
        device: "Агрегатты қосу",
        farm: "Шаруашылық қосу",
        new_farm: "Шаруашылық қосу",
        event: "Іс-шараны қосу",
        category: "Санат қосу",
        title: "Атауын қосу"
    },
    show: {
        itself: "Көрсету",
        points: "Картадағы нүктелерді көрсету",
        fields: "Егістікті көрсету",
        starred: "Таңдалғандарды көрсету"
    },
    close: "Жабу",
    understand: "Маған бәрі түсінікті",
    season: ["Маусымды таңдау", "Ағымдағы маусым: "],
    subscription: ["Жазылуды рәсімдеу", "Жазылу дейін"],
    subscribe: "Жазылу",
    get: {
        general: "Тапсырыс беру",
        agrochemical_analysis: "Топырақты агрохимиялық талдауға тапсырыс беру"
    },
    change: {
        general: "Өзгерту",
        password: "Құпиясөзді өзгерту",
        staff: "Қызметкерді өзгерту"
    },
    go_main: "Басты бетке өту",
    download: {
        map: "Картаны жүктеу"
    },
    select: "Таңдау",
    reply: "Жауап беру",
    write: {
        whatsapp: "WhatsApp Жазыңыз",
        operator: "Операторға жазу"
    },
    press_start: "Бастау үшін басыңыз",
    press_continue: "Жалғастыру үшін басыңыз",
    mark_all_read: "Барлығын оқылған деп белгілеу",
    mark: "Таңдау"

}