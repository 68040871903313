export default {
    title: "Guide",
    search: "Search by name..",
    sort: {
        sort_by: "Sort by:",
        a_z: "А-Z",
        popular: "Popular",
        watched: "Previewed"
    },
    category: ["All", "Potatoes", "Winter rape", "Spring rape", "Sugar beet", "Tomatoes", "Oil flax", "Soy", "Corn", "Sunflower", "Winter wheat", "Spring wheat", "Spring barley", "Winter barley", "Vineyards", "Apple tree", "Onions", "Cucumbers", "Rice"],
    preview: {
        farm: {
            title: "Guide to farm",
            detail: "To start the guide go to the farm"
        },
        cell: {
            title: "Guide to the cell",
            detail: "To start the guide go to the cell"
        },
        stats: {
            title: "Guide to the statistics",
            detail: "To start the guide go to the statistics"
        },
        no_data: "You have no processed orders yet"
    }
}