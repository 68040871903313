export default {
    welcome: "Добро пожаловать",
    whoarewe1: "EGISTIC - система управления вашим хозяйством",
    whoarewe2: "Cистема управления хозяйством для сельхозтоваропроизводителей и консультантов в области сельского хозяйства",
    address: 'г. Нур-Султан, пр. Кабанбай батыра 53, Технопарк, офис 13.3',
    mission: {
        1: "“Наша миссия",
        2: "- облегчить управление фермой”",
        content: {
            1: "Система управления фермой EGISTIC основана на реализации интегрированного решения для мониторинга и управления посевными площадями с использованием технологий дистанционного зондирования, высокоточной спутниковой навигации, геоинформационных систем и технологий машинного обучения.",
            2: "История «EGISTIC» начинается в 2018 году, когда Жандос Керимкулов (генеральный директор и соучредитель) увидел технологию облегчения системы управления фермой для фермеров в Казахстане с помощью спутниковых снимков с открытым исходным кодом. На казахстанском рынке есть несколько программных сервисов, которые являются зарубежными, дорогими и сложными в использовании. Вот почему только холдинги могли позволить себе пользоваться такими услугами. В течение года команда Egistic создавала простое программное обеспечение, которое помогло бы фермерам проводить простые операции, чтобы начать использовать спутниковые изображения.",
            3: "Теперь, за два года тестирования и разработки, сервис EGISTIC был расширен, и мы можем сказать, что система имеет полный список сервисов для запуска фермы.",
            3.5: "У нас более 1 000 пользователей, и мы гордимся тем, что наши фермеры имеют доступ к данным спутниковых снимков и погодным данным за 2016 год в один клик."
        }
    },
    application: "Доступно мобильное приложение Egistic. Хотите установить?",
    guide_start: "начать знакомство",
    personal_info: {
        name: "Имя",
        surname: "Фамилия",
        email: "E-mail",
        number: "Номер телефона",
        position: "Должность",
        password: "Пароль",
        permission: "Тип доступа"
    },
    units: {
        he: " га",
        t: " т",
        tenge: " тенге",
        tenge_he: " тенге/га",
        kg_he: " кг/га",
        tg_he: " тг/га",
        centner_he: "ц/га",
        t_he: "т/га",
        km_h: " км/час",
        m_s: " м/с",
        mm: " мм",
        l: " л",
        tg: " тг",
        km: " км",
        m: " м",
        h: " ч"
    },
    language: "Русский",
    dates: {
        date: "Дата",
        today: "Сегодня",
        from: "от ",
        starting_from: "с ",
        to: "до ",
        up_to: "по ",
        format_date: "ДД.ММ.ГГ",
        format_time: "ЧЧ:ММ",
        months: [ "января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"],
        week: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
        month: ["янв", "фев", "мар", "апр", "май", "июн", "июл", "авг", "сен", "окт", "ноя", "дек"]
    },
    process: {
        done: "Сделано",
        cancelled: "Отменено",
        planned: "Запланировано",
        in_process: "В процессе",
        outdated: "Просрочено",
        loading: "Идет загрузка",
        broadcasting: "Идет трансляция",
        connection_failed: "Соединение прервано"
    },
    bill: {
        check: "Счёт на оплату",
        number: "Номер:",
        from_date: "От числа:",
        status: "Статус:",
        paid: "Оплачено",
        not_paid: "Не оплачено",
        total: "Итого:"
    },
    download_file: "Скачать файл"
}