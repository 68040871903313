export default {
    main: "Басты бет",
    home: "Бастапқы бет",
    about_us: "Біз туралы",
    services: "Қызметтер",
    news: "Жаңалықтар",
    support: "Көмек",
    farm: "Шаруашылығы",
    contacts: "Байланыстар",
    workspace: {
        main: "Басты бет",
        workspace: {
            title: "Жұмыс облысы",
            option: "Барлығын көрсету"
        },
        employees: "Қызметкерлер",
        farm: "Шаруашылығы",
        autopark: "Автопарк",
        storage: "Қойма",
        guide: "Анықтамалық",
        dispatcher: "Диспетчерлік",
        profile: "Бағдар"
    }
}