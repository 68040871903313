export default {
    title: "Storage activity log",
    general: "Activity log",
    list: "Storage list",
    list_by_farms: "List by farms",
    logs: {
        period: "Period",
        income: "Income",
        expense: "Expense",
        movement: "Movement",
        storage: "Storage",
        farm: "Farm",
        return: "Return",
        writeoff: "Write-off",
        operation: "Operation",
        edit: "Editing",
        equipment: "Equipment",
        history: "History",
        name: "Title",
        fields: {
            type: "Type",
            desc: "Description",
            date: "Date",
            title: "Title",
            title_add: "Add title",
            title_change: "Change title",
            income_type: "Income type",
            expense_type: "Expense type",
            operation_type: "Operation type",
            amount: "Amount",
            scaling: "Unit",
            scaling_full: "Scaling unit",
            cost: "Cost",
            cost_for_one: "Cost for one",
            sum: "Total sum",
            sender: "Sender",
            receiver: "Recipient",
            supplier: "Supplier",
            new: "edited"
        },
        income_type: {
            supplier: "Supplier",
            employee: "Employee",
            return: "Clinet's return",
            event: "Event remainings"
        },
        expense_type: {
            employee: "Employee",
            customer: "Customer",
            refill: "Refill",
            maintenance: "Equipment maintenance"
        },
        total: "Total"
    }
}