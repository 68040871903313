export default {
    auth: {
        login0: "Login",
        login: "Login / Registration",
        login_google: "Login via Google",
        sign_up: "Sign up",
        sign_up_free: "Sign up for free",
        log_out: "Log out"
    },
    save: {
        general: "Save",
        machine: "Save the machine",
        farm: "Save the farm"
    },
    actions: {
        title: "Actions",
        access: "Access",
        change: "Change",
        delete: "Delete",
        download: "Download PDF",
        print: "Print",
        add: "Add",
        end: "Complete",
        edit: "Edit",
        upload: "Upload"
    },
    download_guide: "Download User Guide in PDF",
    update: "Update",
    create: "Create",
    continue: "Continue",
    apply: "Apply",
    learn_more: "Learn more",
    watch_video: "Watch video",
    go_back: "Go back",
    goto_channel: "Go to the channel",
    go_on: "Next",
    skip: "Skip",
    steps: {
        prev: "Previous",
        next: "Next"
    },
    new_order: "new order",
    cancel: "cancel",
    cancel_division: "cancel the split",
    recover: "recover",
    ok: "OK",
    exit: "Exit",
    add: {
        general: "add",
        staff: "add an employess",
        machine: "add a machine",
        device: "add a device",
        farm: "add a farm",
        new_farm: "add a farm",
        event: "add an event",
        category: "add a category"
    },
    show: {
        itself: "Show",
        point: "show the points on the map",
        fields: "show the fields",
        starred: "Show starred",
        all: "Show all"
    },
    close: "close",
    understand: "I understand everything",
    season: ["Season selection", "Current season: "],
    subscription: ["Subscribe", "You have already subscribed"],
    subscribe: "subscribe",
    get: {
        general: "get",
        agrochemical_analysis: "get soil agrochemical analysis"
    },
    change: {
        general: "change",
        password: "change password"
    },
    go_main: "go to the main page",
    download: {
        map: "download the map"
    },
    select: "select",
    reply: "reply",
    try: "Try",
    write: {
        whatsapp: "write to whatsapp",
        operator: "write to the operator"
    },
    press_start: "Press to start",
    press_continue: "Press to continue",
    mark_all_read: "Mark all as read",
    mark: "Select"
}