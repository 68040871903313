export default {
    title: "Автопарк",
    machines: "Техники",
    devices: "Агрегаты",
    add_machine: {
        title: " технику",
        class: "Марка",
        model: "Модель",
        state_num: "Госномер",
        IMEI: "IMEI",
        capacity: "Вместимость бака (л)",
        consumption: "Расход топлива (л/га)",
        photo: "Фото",
        category: {
            title: "Категория",
            categories: ["Тракторы", "Комбайны", "Самоходная косилка", "Кормоуборочные комбайны", "Самоходные разбрасыватели"]
        }
    },
    add_record: {
        title: " ",
        type_placeholder: "Выберите вид",
        type: "Вид технического обслуживания",
        repair_stage: "Стадия ремонта",
        repair_stage_placeholder: "Выберите стадию ремонта",
        consumables: "Расходные материалы и запчасти",
        status: "Статус",
        status_placeholder: "Выберите статус",
        date_range: "Продолжительность",
        mileage: "Пробег",
        mileage_placeholder: "Введите пробег",
        hours: "Моточасы",
        hours_placeholder: "Введите моточасы",
        sum: "Общая стоимость",
        sum_placeholder: "Введите общую стоимость",
        note: "Примечание",
        errors: {
            type: "Выберите вид технического обслуживания",
            repair_stage: "Выберите стадию ремонта",
            sum: "Введите общую стоимость",
            date_range: "Заполните продолжительность",
            no_records: "У вас нет актуальных записей"
        }
    },
    edit_machine: "Изменить технику",
    add_device: {
        title: " агрегат",
        name: "Название",
        width: "Ширина охвата (м)",
        photo: "Фото"
    },
    edit_device: "Изменить агрегат"
}