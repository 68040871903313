export default {
    title: "Аанықтамалық",
    search: "Атауы бойынша іздеу..",
    sort: {
        sort_by: "Бойынша сұрыптау:",
        a_z: "А-Я",
        popular: "Әйгілі",
        watched: "Қарастырылатын"
    },
    category: ["Барлық", "Картоп", "Күзгі рапс", "Жаздық рапс", "Қант қызылшасы", "Қызанақ", "Майлы зығыр", "Соя", "Жүгері", "Айқабақ", "Күздік бидай", "Жаздық бидай", "Жаздық арпа", "Күздік арпа", "Жүзім", "Алма ағашы", "Пияз", "Қияр", "Күріш"],
    preview: {
        farm: {
            title: "Шаруашылықпен танысу",
            detail: "Танысуды бастау үшін шаруашылығына өтіңіз"
        },
        cell: {
            title: "Ұяшықпен танысу",
            detail: "Танысуды бастау үшін ұяшыққа өтіңіз"
        },
        stats: {
            title: "Көрсеткіштермен танысу",
            detail: "Танысуды бастау үшін көрсеткіштерге өтіңіз"
        },
        no_data: "Сізде әлі өңделген тапсырыстар жоқ"
    }
}