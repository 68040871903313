export default {
    ruler: "Сызғыш",
    zoom_in: "Үлкейту",
    zoom_out: "Азайту",
    loading: "Деректер жүктеледі..",
    layers: {
        title: "Қабаттар",
        snow: "Қар картасы",
        soil: "Топырақ картасы",
        relief: "Рельеф картасы"
    },
    distance: "Қашықтық",
    area: "Аумақ",
    coordinate: "Координаталар",
    location: "Менің орналасқан жерім",
    follow: "Қадағалау",
    snapshot: "Сурет",
    background: {
        title: "Растр",
        satellite: "Жер серік",
        scheme: "Схема"
    },
    welcome: "Қош келдіңіздер, ",
    info: "Egistic Limited мүмкіндіктері төменде келтірілген",
    guide: {
        congratulations: "Құттақтаймыз",
        msg_final: "Сіз жұмыс орныңызбен тансыуды бойынша қысқа курсты аяқтадыңыз! Мен Сізге әр бөліммен танысуыңызға көмектесіп отырамын.",
        btn_final: "Жалғастыру",
        indicators: "Көрсеткіштер",
        msg_indicators: "Өз өрістеріңізді төрт индекс бойынша талдаңыз, проблемалық аймақтарды және қоқыстарды анықтаңыз",
        btn_indicators: "Танысуды бастау"
    }
}