import {IState} from './'

export type State = IState

export const Action = {
}

export const Mutation = {
    changeNeedToConfirmMail: 'changeNeedToConfirmMail',
    setSignError: 'setSignError',
    setCurrentRoute: 'setCurrentRoute',
    setErrorHandler: 'setErrorHandler'
}

export const Getter = {
    getCurrentRoute: 'getCurrentRoute',
    getSignViewData: 'getSignViewData',
    getErrorHandler: 'getErrorHandler'
}
