import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'

import OutsideClickDirective from '@/directives/OutsideClick'
import BaseIcon from '@/components/BaseIcon.vue'
import Checkbox from '@/components/Checkbox.vue'
import SwitchButton from '@/components/SwitchButton.vue'

import VueAnalytics from 'vue-analytics'
import device from "vue-device-detector"
import GAuth from 'vue-google-oauth2'
import VueYandexMetrika from 'vue-yandex-metrika'
import i18n from './i18n'
import VuePhoneNumberInput from 'vue-phone-number-input'
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import VueSplide from '@splidejs/vue-splide'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import { VueMaskDirective } from 'v-mask'
import JsonExcel from 'vue-json-excel'
import VueSimpleAlert from "vue-simple-alert"

declare module 'vue/types/vue' {
    interface Vue {
        $gAuth: any
    }
}
Vue.config.productionTip = false

declare module 'vue/types/vue' {
    interface Vue {
        $ga: VueAnalytics
    }
}

Vue.use(VueSplide)
// 789257002799-j114kmoojh1j2s6r539pjbof6j6qdpfa.apps.googleusercontent.com <- new
// 623625153858-ueb8h6g9bh3s5ag068d7h05qp3ursc17.apps.googleusercontent.com <- old
Vue.use(GAuth, { clientId: '623625153858-ueb8h6g9bh3s5ag068d7h05qp3ursc17.apps.googleusercontent.com'})
Vue.use(VueAnalytics, {
    id: 'UA-135349691-1',
    router,
    checkDuplicatedScript: true,
    debug: true
})
Vue.use(VueYandexMetrika, {
    id: 73800022,
    router,
    env: 'production'
})
Vue.use(device)
Vue.use(VueFilterDateFormat)
Vue.use(VueSimpleAlert, {
    confirmButtonColor: '#4B970F',
    cancelButtonColor: '#E0E2D8',
    cancelButtonText: 'Отмена',
    reverseButtons: false,
    customClass: {
        confirmButton: 'swal2-button--confirm',
        cancelButton: 'swal2-button--cancel'
    }
})

Vue.component('vue-phone-number-input', VuePhoneNumberInput)
Vue.component('clip-loader', ClipLoader)
Vue.component('downloadExcel', JsonExcel)
Vue.component('BaseIcon', BaseIcon)
Vue.component('Checkbox', Checkbox)
Vue.component('SwitchButton', SwitchButton)
Vue.directive('outside-click', OutsideClickDirective)

Vue.directive('mask', VueMaskDirective)

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')


export const tokenExpiredLogout = () => {
    if (store.getters.IS_SIGNED_IN) {
        store.dispatch('LOG_OUT', {returnroute: 'signin'})
    }
}

export const settingErrorHandler = (responseStatus: any, currentResponse: any) => {
    const newId = Math.floor(Math.random() * Date.now())
    store.commit('setErrorHandler', {
        id: newId,
        status: responseStatus,
        response: currentResponse
    })
}