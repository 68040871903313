export default {
    temperature: "Temperat..",
    humidity: "Humidity",
    wind: "Wind",
    rainfall: "Rainfall",
    directions: {
        north: "N",
        north_east: "NE",
        east: "E",
        south_east: "SE",
        south: "S",
        south_west: "SW",
        west: "W",
        north_west: "NW"
    }
}