









import { Component, Vue, Prop} from 'vue-property-decorator'
@Component
export default class SwitchButton extends Vue {
    @Prop({type: Boolean})
    private active!: boolean
}
