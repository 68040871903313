export default {
    cultures: {
        crops: {
            title: "Crops",
            1: {
                title: "Grain breads (winter and spring)",
                wheat: {
                    title: "wheat",
                    types: ["Winter hard wheat", "Winter soft wheat", "Soft spring wheat", "Hard spring wheat", "Turgidum wheat"]
                },
                rye: {
                    title: "rye",
                    types: ["Winter rye"]
                },
                barley: {
                    title: "barley",
                    types: ["Winter barley", "Spring barley"]
                },
                oat: {
                    title: "oat",
                    types: ["Spring oats"]
                },
                triticale: {
                    title: "triticale",
                    types: ["Winter triticale"]
                }
            },
            2: {
                title: "Spring cereals and plants of other families",
                maize: {
                    title: "maize",
                    types: ["Maize"]
                },
                millet: {
                    title: "millet",
                    types: []
                },
                rice: {
                    title: "rice",
                    types: ["Rice"]
                },
                sorghum: {
                    title: "sorghum",
                    types: ["Grain sorghum"]
                },
                buckwheat: {
                    title: "buckwheat",
                    types: ["Buckwheat"]
                }
            },
            3: {
                title: "Cereal legumes",
                peas: {
                    title: "Peas",
                    types: ["Sowing peas"]
                },
                beans: {
                    title: "broad beans",
                    types: []
                },
                lentil: {
                    title: "lentil",
                    types: ["Lentil"]
                }
            }
        },
        root_vegetables: {
            title: "Root vegetables, tubers, melons, forage cabbage",
            1: {
                title: "Root vegetables",
                sugar_beet: {
                    title: "Sugar beet",
                    types: ["Beetroot"]
                },
                fodder_beet: {
                    title: "fodder beet",
                    types: ["Fodder beets"]
                }
            },
            2: {
                title: "Kale",
                feed_cabbage: {
                    title: "Kale",
                    cabbage_white: {
                        title: "White cabbage"
                    },
                    cabbage_red: {
                        title: "Red cabbage"
                    },
                    cabbage_colorful: {
                        title: "Cauliflower"
                    },
                    cabbage_peking: {
                        title: "Cabbage Peking"
                    },
                    broccoli: {
                        title: "Broccoli"
                    },
                    salad: {
                        title: "Salad"
                    },
                    cabbage_savoy: {
                        title: "Savoy cabbage"
                    }
                },
                kohlrabi: {
                    title: "kohlrabi"
                }
            },
            3: {
                title: "Tubers",
                potato: {
                    title: "Potatoes",
                    types: ["Potatoes"]
                },
                artichoke: {
                    title: "earthen pear"
                }
            },
            4: {
                title: "Melons",
                watermelon: {
                    title: "Watermelon",
                    types: ["АрбWatermelonуз"]
                },
                melon: {
                    title: "melon",
                    types: ["Melon"]
                },
                pumpkin: {
                    title: "pumpkin",
                    types: ["Fodder pumpkin", "Pumpkin"]
                }
            }
        },
        forage_crops: {
            title: "Fodder crops",
            1: {
                title: "Perennial legumes",
                clover: {
                    title: "Clover",
                    types: ["Red clover"]
                },
                lucerne: {
                    title: "lucerne",
                    types: ["Lucerne"]
                },
                sainfoin: {
                    title: "sainfoin"
                },
                other: {
                    title: "other ..."
                }
            },
            2: {
                title: "Cereals, perennial",
                timothy: {
                    title: "Timofeevka"
                },
                fescue: {
                    title: "fescue",
                    types: ["Meadow fescue", "Grooved fescue", "Reed fescue"]
                },
                wheatgrass: {
                    title: "wheatgrass",
                    types: ["Wheatgrass"]
                }
            },
            3: {
                title: "New perennial forage plants",
                sosnovsy_hogweed: {
                    title: "Sosnovsky's hogweed"
                },
                weyrich_highlander: {
                    title: "Weirich Highlander"
                },
                goat_rue: {
                    title: "oriental goat's rue"
                }
            },
            4: {
                title: "Annual legumes",
                vicky: {
                    title: "Vika"
                },
                seradella: {
                    title: "seradella"
                },
                crimson_clover: {
                    title: "crimson clover"
                },
                other: {
                    title: "other ..."
                }
            },
            5: {
                title: "Cereals, annuals",
                sudanese_grass: {
                    title: "Sudanese grass"
                },
                panic: {
                    title: "panic"
                },
                plague: {
                    title: "plague"
                }
            },
            6: {
                title: "New annual forage plants",
                whorled_mallow: {
                    title: "Whorled mallow"
                },
                oil_radish: {
                    title: "oil radish"
                }
            }
        },
        oil_bearing: {
            title: "Oil-bearing and essential oil-bearing",
            1: {
                title: "Oilseeds",
                sunflower: {
                    title: "Sunflower",
                    types: ["Sunflower"]
                },
                safflower: {
                    title: "safflower",
                    types: ["Safflower"]
                },
                mustard: {
                    title: "mustard",
                    types: ["Gray mustard", "Sarepta mustard", "Salad mustard"]
                },
                rape: {
                    title: "rape",
                    types: ["Winter rape for feed", "Winter rape", "Spring rape", "Spring rape for feed"]
                },
                mushroom: {
                    title: "Ryzhik",
                    types: ["Spring Ryzhik"]
                },
                castor_oil_plant: {
                    title: "castor oil plant",
                    types: ["Castor oil plant"]
                },
                sesame: {
                    title: "sesame",
                    types: ["Sesame"]
                },
                oil_flax: {
                    title: "Oil flax",
                    types: ["Oil flax"]
                },
                peanut: {
                    title: "peanut"
                },
                perilla: {
                    title: "perilla"
                },
                lallementia: {
                    title: "lallementia"
                }
            },
            2: {
                title: "Essential oil",
                coriander: {
                    title: "Coriander"
                },
                anise: {
                    title: "anise"
                },
                cumin: {
                    title: "cumin"
                },
                peppermint: {
                    title: "peppermint"
                },
                clay_sage: {
                    title: "clary sage"
                }
            }
        },
        spinning: {
            title: "Spinning",
            1: {
                title: "Plants with fiber on seeds",
                cotton: {
                    title: "Cotton"
                }
            },
            2: {
                title: "Bast fiber",
                linen: {
                    title: "Linen"
                },
                hemp: {
                    title: "hemp"
                },
                kenaf: {
                    title: "kenaf"
                }
            }
        },
        tobacco: {
            title: "Tobacco and makhorka",
            1: {
                title: "Narcotic",
                tobacco: {
                    title: "Tobacco",
                    types: ["Tobacco"]
                },
                makhorka: {
                    title: "makhorka"
                }
            }
        }

    },
    gistogram: {
        ndvi: ["Objects that are not related to the vegetation", "Bare soil", "Low discharged vegetation", "Weak vegetation", "dense vegetation"],
        ndmi: ["Open soil", "Almost no vegetation", "Very low vegetation", "Low vegetation, dry or very low vegetation, moisture",
            "Medium-low vegetation, low moisture or low vegetation, medium moisture", "Medium vegetation, low moisture, or medium-low vegetation, medium moisture",
            "Medium vegetation, low moisture, or medium-low vegetation, medium moisture", "Medium-high vegetation, low moisture or medium vegetation, medium moisture",
            "High level of vegetation, sufficient moisture", "Very high level of vegetation, sufficient moisture", "Full vegetation coverage or swampiness (water bodies)"]
    },
    phenophase: {
        crop_1: {
            title: "Winter wheat",
            data: ["Shoots", "Tillering", "After melting snow (spring)", "Getting into the tube", "Heading – flowering", "Grain formation", "Milk ripeness", "Before harvesting"]
        },
        crop_2: {
            title: "Spring wheat",
            data: ["Shoots, third leaf", "Tillering", "Getting into the tube", "Heading, flowering", "Tying grain - milk ripeness", "Milky-wax ripeness", "Wax ripeness", "Full ripeness"]
        },
        crop_3: {
            title: "Spring barley",
            data: ["Shoots", "Tillering", "Getting into the tube", "Heading, flowering", "Tying grain - milk ripeness", "Milky-wax ripeness", "Wax ripeness", "Full ripeness"]
        },
        crop_4: {
            title: "Winter barley",
            data: ["Shoots", "Tillering", "Getting into the tube", "Ligula stage", "Heading", "Flowering", "Milky ripeness", "Wax ripeness", "Full ripeness"]
        },
        crop_5: {
            title: "Corn",
            data: ["shoots", "first leaf", "3-5 leaves", "tube exit", "Stem growth", "sweeping", "panicle flowering", "caryopsis formation", "caryopsis filling", "caryopsis ripening"]
        },
        crop_6: {
            title: "Sunflower",
            data: ["seedlings", "budding", "flowering", "seed filling", "Ripening"]
        },
        crop_7: {
            title: "Linen",
            data: ["Shoots", "Stem lengthening", "Butinization", "Flowering", "Green bolls", "Brown bolls", "Full maturity"]
        },
        crop_8: {
            title: "Winter rape",
            data: ["Shoots", "Cotyledons", "Rosette", "Stem growth", "Budding", "Beginning of flowering", "Mid-flowering", "Formation of pods", "Ripening", "Full maturation"]
        },
        crop_9: {
            title: "Spring rape",
            data: ["Shoots", "Cotyledons", "Rosette", "Stem growth", "Budding", "Beginning of flowering", "Mid-flowering", "Formation of pods", "Ripening", "Full maturation"]
        },
        crop_10: {
            title: "Soy",
            data: ["shoots", "Branching", "Budding", "Flowering", "Bean formation", "The beginning of ripening", "full ripening"]
        },
        crop_11: {
            title: "Safflower",
            data: ["Shoots", "Rosette", "stemming", "branching", "budding", "Flowering", "Ripening", "Full ripeness"]
        }
    }
}