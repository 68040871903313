import { tokenExpiredLogout, settingErrorHandler } from '@/main'

export const errorHandler = (response: any) => {
    if (response.status >= 100 && response.status <= 199) {
        /* Informational responses */
    } else if (response.status >= 200 && response.status <= 299) {
        /* Successful responses */
    } else if (response.status >= 300 && response.status <= 399) {
        /* Redirects */
    } else if (response.status >= 400 && response.status <= 499) {
        /* Client errors */
        if (response.status === 401) {
            /* Unauthorized */
            tokenExpiredLogout()
        }
    } else if (response.status <= 500 && response.status <= 599) {
        /* Server errors */
        // settingErrorHandler(response.status, response)
    }
}