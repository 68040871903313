export default {
    title: "Жұмыс жобасы",
    plural: "Жұмыс жобалары",
    area: "Жұмыс облысы",
    new: "Жаңа жоба",
    save: "Жобаны сақтау",
    add: "Жобаны қосу",
    edit: {
        change: "Жұмыс жобасын өзгерту",
        add: "Жұмыс жобасын қосу"
    },
    details: {
        title: "Атауы",
        photo: "Сурет",
        owner: "Иеленуші",
        position: "Лауазым"
    },
    types: {
        all: "Барлық жобалар",
        my: "Менің жобаларым",
        other: "Бөтен жобалар"
    },
    sort: {
        title: "Сұрыптау",
        by_name: "Есім бойынша",
        by_date: "Күн бойынша",
        by_area: "Жалпы алаңы бойынша",
        ascending: "Өсуі бойынша",
        descending: "Кему бойынша",
        access_type: "Кіру түрі бойынша",
        notification_type: "Хабарландыру түрі бойынша"
    },
    participants: {
        list: "Қатысушылар тізімі",
        fullname: "Қызметкердің аты-жөні",
        last_access: {
            title: "Соңғы кіру",
            not_activated: "Белсендірілді емес",
            offline: "Кірген жоқ",
            just_now: "Жаңа ғана",
            sec: "Бір секунд бұрын | {n} секундтар| {n} секунд бұрын",
            min: "Бір минут бұрын | {n} минуттар| {n} минут бұрын",
            h: "Сағат бұрын | {n} сағат| {n} сағат бұрын",
            day: "Күні бұрын | {n} күн бұрын | {n} күн бұрын",
            week: "Бір апта бұрын| {n} апта бұрын | {n} апта бұрын",
            month: "Бірнеше ай бұрын | {n} ай бұрын | {n} ай бұрын",
            year: "Бір жыл бұрын | {n} жыл бұрын | {n} жыл бұрын"
        },
        confirmation_message: "{name}  сізді жобаға қосты. Жобасын қабылдауы тиіс?"
    }
}