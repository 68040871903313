import Vue from 'vue'
import Vuex from 'vuex'
import GeneralModule from './modules/general'
import AuthModule from './modules/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    GeneralModule,
    AuthModule
  }
})

const getModule = (ModuleName: string) => (state: any) => state[ModuleName]

export const GeneralStore = getModule('GeneralModule')
export const AuthStore = getModule('AuthModule')
