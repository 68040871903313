export default {
    title: "Диспетчерская",
    manage: "Настройки уведомлений",
    permissions: {
        alarms: {
            title: "Оповещения",
            workspace_change: "Приглашение, вход или выход с проекта",
            notice_mention: "Отметка в заметках",
            notice_reply: "Ответ на заметку",
            response_new_image: "Новые снимки с проблемными зонами",
            croprotation_empty: "Незаполненная история полей",
            task_add: "Добавление в задачах",
            tractor_motion: "Начало и конец работы техники",
            payment_done: "Оплата подписки"
        },
        warnings: {
            title: "Тревоги",
            notice_field_not_visit: "Неосмотренные поля",
            weather_draught: "Отсутствие осадков",
            response_dangerous_zone: "Новые снимки с проблемными зонами",
            task_incomplete: "Невыполненные задачи",
            telematic_max_speed: "Превышение скорости",
            telematic_out_of_field: "Работа за границей полей",
            tractor_stop: "Простой техники",
            tractor_breakdown: "Поломка техники",
            tractor_expired_maintenace: "Просроченное обслуживание техники",
            telematic_fuel: "Телематика - топливо (слив, переполнение бака)",
            telematic_no_info: "Телематика - отсутствие данных(проблема с сетью, отключение, падение напряжение в датчике)"
        }
    },
    notif_categories: {
        all: "Все",
        notification: "Оповещения",
        alarm: "Тревоги",
        spam: "Спам"
    },
    msg_categories: {
        all: "Все",
        starred: "Избранные",
        read: "Прочитанные",
        not_read: "Непрочитанные",
        not_marked: "Без отметок"
    },
    msg_actions: {
        spam: "В спам",
        share: "Поделиться",
        print: "Распечатать",
        to_notes: "Перейти к заметке",
        to_projects: "Перейти к проектам",
        to_farm: "Перейти к хозяйству"
    }
}