import { Module, GetterTree, ActionTree, MutationTree } from 'vuex'
import { GetterNames, MutationNames } from '../../types'
import router from '@/router'
import { Route } from 'vue-router'
const states = {
    signViewData: {
        needToConfirmMail: false,
        signError: {
            needToShow: false,
            details: ''
        }
    },
    currentRoute: router.currentRoute,
    errorHandler: new Array<any>()
}

export type IState = typeof states

const mutations: MutationTree<IState> = {
    [MutationNames.changeNeedToConfirmMail](state, val: boolean) {
        state.signViewData.needToConfirmMail = val
    },

    [MutationNames.setSignError](state, { needToShow, details}) {
        state.signViewData.signError.needToShow = needToShow
        state.signViewData.signError.details = details
    },

    [MutationNames.setCurrentRoute](state, route: Route) {
        state.currentRoute = route
    },

    [MutationNames.setErrorHandler](state, val: any) {
        const index = state.errorHandler.findIndex((x: any) => x.id === val.id)
        if (index !== -1) {
            state.errorHandler.splice(index, 1)
        } else {
            state.errorHandler.push(val)
        }
    }
}

const actions: ActionTree<IState, any> = {
}

const getters: GetterTree<IState, any> = {
    [GetterNames.getCurrentRoute]: state => {
        return state.currentRoute
    },

    [GetterNames.getSignViewData]: state => {
        return state.signViewData
    },

    [GetterNames.getErrorHandler]: state => states.errorHandler
}

const auth: Module<IState, any> = {
    state: states,
    actions,
    mutations,
    getters
}

export default auth
