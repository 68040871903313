export default {
    main: {
        services: "Наши услуги: ",
        to_farm: "Перейти к хозяйству",
        faq: {
            title: "Часто задаваемые вопросы: ",
            subtitle: "Если у Вас все еще остались вопросы, Вы можете позвонить по номеру +7 705 739 3965."
        },
        for_pc: "Для компьютера",
        for_mobile: "Для iPhone и iPad",
        for_tablets: "Для телефонов и планшетов Android",
        feedback: "Отзывы клиентов",
        media: "О нас пишут в СМИ:",
        mobility: "Мобильность и эффективность. Egistic доступен:"
    },
    about_us: {
        team: "Наша команда",
        partners: "Стратегическое партнерство",
        support: "Поддержка"
    },
    services: "Egistic предоставляет следующие услуги:",
    news: {
        title: "Новости",
        others: "Другие новости",
        sections: {
            all: "Все",
            news: "Новости",
            promotions: "Акции",
            updates: "Обновления"
        }
    },
    support: {
        webinars: {
            title: "Вебинары",
            details: "Видеозаписи вебинаров от команды EGISTIC, в которых подробно рассказывается о том, как использовать нашу платформу для решения задач и к каким результатом это приведет. Также доступно расписание будущих вебинаров.",
            content: {
                future: "Расписание вебинаров",
                past: "Записи вебинаров"
            }
        },
        lessons: {
            title: "Видеоинструкции",
            details: "Здесь Вы можете найти подробные пошаговые инструкции функционала EGISTIC для Вас и Ваших сотрудников, а также посмотреть обзор новых обновлений платформы.",
            content: {
                lessons: "Видеоинструкции",
                where_start: "С чего начать?",
                how_manage: "Как управлять хозяйством?",
                features: "Функционал",
                all: "Все видео"
            }
        },
        user_guide: {
            title: "Руководство пользователя",
            menu: "Содержание",
            details: "Руководство пользователя - это полезный ресурс для Вас и Вашей команды, представленный в виде текста и фотографий. Для Вашего удобства Руководство можно скачать в формате PDF.",
            content: {
                button: "Содержание",
                title: "Руководство пользователя сервиса «Egistic»"
            }
        }
    }
}