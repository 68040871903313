export const environment = 'cabinet.openlayers.kz' // development
// export const environment = 'cabinet.egistic.kz' // production

// const domain = 'localhost' // localhost
const domain = 'openlayers' // debug
// const domain = 'demoegistic' // development
// const domain = 'egistic' // production

export const mainDomain = () => {
    if (domain.includes('localhost')) return '.localhost.kz' // localhost
    else if (domain.includes('openlayers')) return '.openlayers.kz' // debug
    else if (domain.includes('demoegistic')) return '.demoegistic.kz' // development
    else if (domain.includes('egistic')) return '.egistic.kz'  // production
    else return '.egistic.kz'  // production
}

export const landingUrl = () => {
    if (domain.includes('localhost')) return 'http://localhost.kz:8080' // localhost
    else if (domain.includes('openlayers')) return 'https://openlayers.kz' //
    else if (domain.includes('demoegistic')) return 'https://demoegistic.kz' // development
    else if (domain.includes('egistic')) return 'https://egistic.kz'  // production
    else return 'https://egistic.kz'  // production
}

export const loginUrl = () => {
    if (domain.includes('localhost')) return 'http://login.localhost.kz:8080' // localhost
    else if (domain.includes('openlayers')) return 'https://login.openlayers.kz' // debug
    else if (domain.includes('demoegistic')) return 'https://login.demoegistic.kz' // development
    else if (domain.includes('egistic')) return 'https://login.egistic.kz'  // production
    else return 'https://login.egistic.kz'  // production
}

export const farmUrl = () => {
    if (domain.includes('localhost')) return 'http://farm.localhost.kz:8080' // localhost
    else if (domain.includes('openlayers')) return 'https://farm.openlayers.kz' // development
    else if (domain.includes('demoegistic')) return 'https://farm.demoegistic.kz' // development
    else if (domain.includes('egistic')) return 'https://farm.egistic.kz'  // production
    else return 'https://farm.egistic.kz'  // production
}

export const consultingUrl = () => {
    if (domain.includes('localhost')) return 'http://consulting.localhost.kz:8080' // localhost
    else if (domain.includes('openlayers')) return 'https://consulting.openlayers.kz' // debug
    else if (domain.includes('demoegistic')) return 'https://consulting.demoegistic.kz' // development
    else if (domain.includes('egistic')) return 'https://consulting.egistic.kz'  // production
    else return 'https://consulting.egistic.kz'  // production
}

export const marketUrl = () => {
    if (domain.includes('localhost')) return 'http://market.localhost.kz:8080' // localhost
    else if (domain.includes('openlayers')) return 'https://market.openlayers.kz' // debug
    else if (domain.includes('demoegistic')) return 'https://market.demoegistic.kz' // development
    else if (domain.includes('egistic')) return 'https://market.egistic.kz'  // production
    else return 'https://market.egistic.kz'  // production
}

export const geoUrl = () => {
    if (domain.includes('localhost')) return 'http://nuxt.localhost.kz:8080' // localhost
    else if (domain.includes('openlayers')) return 'https://nuxt.openlayers.kz' // debug
    else if (domain.includes('demoegistic')) return 'https://nuxt.demoegistic.kz' // development
    else if (domain.includes('egistic')) return 'https://nuxt.egistic.kz'  // production
    else return 'https://nuxt.egistic.kz'  // production
}