export default {
    weather: "Посмотреть погоду",
    first_order: "Сделать первый заказ",
    culture: {
        search: "Поиск по сорту",
        title: "Полевая культура",
        type: "Вид",
        variety: {
            title: "Сорт",
            name: "Название сорта"
        }
    }
}