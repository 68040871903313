export default {
    title: "Working project",
    plural: "Working projects",
    area: "Workspace",
    new: "New project",
    save: "Save the project",
    add: "Add the project",
    edit: {
        change: "Change working projects",
        add: "Add working projects"
    },
    details: {
        title: "Title",
        photo: "Photo",
        owner: "Owner",
        position: "Position"
    },
    types: {
        all: "All projects",
        my: "My projects",
        other: "Other's projects"
    },
    sort: {
        title: "Sort",
        by_name: "by name",
        by_date: "by date",
        by_area: "by area",
        ascending: "ascending order",
        descending: "descending order",
        access_type: "by access type",
        notification_type: "by notification type"
    },
    participants: {
        list: "Participants list",
        fullname: "Employee's fullname",
        last_access: {
            title: "Last access",
            not_activated: "Not activated",
            offline: "Haven't accessed yet",
            just_now: "Just now",
            sec: "A second ago | {n} seconds ago",
            min: "A minute ago | {n} minutes ago",
            h: "An hour ago | {n} hours ago",
            day: "Yesterday | {n} days ago",
            week: "Last week | {n} weeks ago",
            month: "Last month | {n} months ago",
            year: "Last year | {n} years ago"
        },
        confirmation_message: "{name}  добавил(-а) Вас в проект. Принять проект?"
    }
}