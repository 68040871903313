export default {
    title0: "Log in",
    title: "Log in / Registration",
    register: "Or | sign up for free",
    number: "Mobile number or e-mail",
    numberOnly: "Mobile number",
    remember: "Remember me",
    password_forgotten: "Forgot password?",
    email_confirm: "Email confirmation",
    password_reset: {
        title: "Password recovery",
        explanation_1: "Enter your email address for password recovery",
        notification: "A confirmation link has been sent to your email address |. Please follow this link to log in.",
        explanation_2: "Create a new password",
        new_password: "New password",
        password_repeat: "Confirm your password",
        success: "The password was successfully changed!",
        message: "Please log in using your new password"
    },
    errors: {
        google: "Problem with google account, try again",
        typo: "Wrong username or password entered",
        email: "Incorrect format of email",
        not_found: "User with this email was not found",
        server: "Server error",
        data: "Data entered incorrectly",
        password: "The password must contain at least 6 characters"
    },
    loginSmsSubtitle: "Enter your password for account log in",
    next: "Next",
    codeOnSms: "Enter the code from the sms",
    subtitleCodeOnSms: "We have sent a confirmation code to the number",
    code: "Enter the code",
    passwordSignUpSuccess: "You have successfully passed the SMS confirmation. Set a password",
    passwordSignUpAlready: "The number has already passed SMS verification. Set a password",
    successSignUp: "You have successfully registered",
    successSignIn: "Enter the Login number",
    successVerification: "You have successfully passed Verification",
    confirmPerson: "Confirm your identity",
    confirmPersonText: "We are updating the login and registration system. Now you can log in using your phone number, since you are registered via Email, please specify your phone number",
    confirmPersonWithNumber: "We are updating the login and registration system. Click next for SMS verification. In the future, the entrance to the site will be through the specified number",
    confirmPersonWithoutNumber: "We are updating the login and registration system. Specify the phone number for SMS verification. In the future, the entrance to the site will be through the specified number",
    confirmPersonChangeUsername: "We are updating the login and registration system. Specify the SMS code that was sent to the number"
}