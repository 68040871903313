export default {
    ruler: "Линейка",
    zoom_in: "Увеличить",
    zoom_out: "Уменьшить",
    loading: "Данные загружаются..",
    layers: {
        title: "Слои",
        snow: "Карта снега",
        soil: "Карта почвы",
        relief: "Карта рельефа"
    },
    distance: "Расстояние",
    area: "Площадь",
    coordinate: "Координаты",
    location: "Мое местоположение",
    follow: "Следить",
    snapshot: "Снимок",
    background: {
        title: "Подложка",
        satellite: "Спутник",
        scheme: "Схема"
    },
    welcome: "Добро пожаловать, ",
    info: "Ниже перечислены возможности Egistic Limited",
    guide: {
        congratulations: "Поздравляю",
        msg_final: "Вы завершили краткий курс знакомства вашей рабочей области! Я вам буду помогать изучать каждый раздел",
        btn_final: "приступим",
        indicators: "Показатели",
        msg_indicators: "Анализируйте свои поля по четырем индексам, определяйте проблемные зоны и засоренность",
        btn_indicators: "начать знакомство"
    }
}