import auth from './auth/index'
import landing from './landing/index'
import workspace from './workspace/index'

const headers = {
    auth,
    landing,
    workspace
}

export default headers