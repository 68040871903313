import Cookies from 'js-cookie'
import { environment, mainDomain, loginUrl, landingUrl, farmUrl, consultingUrl, marketUrl, geoUrl } from './environment'

const AUTH_HEADER_PREFIX = 'Token'
export const COOKIE_KEY_AUTH_HEADER = 'token'
export const PASSWORD_MIN_LENGTH = 6
export const LAST_LANGUAGE_HAEDER = 'lastusedlanguage'
export const LAST_ORIGIN_HEADER = 'lastoriginheader'

export const baseUrl = `https://${environment}`
export const apiUrl = `${baseUrl}/api/v1`
export const apiUrl3 = `${baseUrl}/api/v3`

export const isLoggedIn = () => {
    const authHeader = Cookies.get(COOKIE_KEY_AUTH_HEADER, { domain: mainDomain() })
    if (authHeader === undefined) return false
    return authHeader.indexOf(AUTH_HEADER_PREFIX) !== -1
}

export const clearCookies = () => {
    Cookies.remove(COOKIE_KEY_AUTH_HEADER)
    Cookies.remove(COOKIE_KEY_AUTH_HEADER, { domain: mainDomain() })
}

export const getAuthToken = () => {
    return Cookies.get(COOKIE_KEY_AUTH_HEADER, { domain: mainDomain() })
}

export const saveAuthHeaderToCookie = (token: string, time: number) => {
    clearCookies()
    Cookies.set(
        COOKIE_KEY_AUTH_HEADER,
        `${AUTH_HEADER_PREFIX} ${token}`,
        { expires: time, domain: mainDomain() }
    )
}

export const openCrossOriginWebSite = (domain: string, path?: string) => {
    let url = ''
    if (domain === 'landing') url = landingUrl()
    else if (domain === 'login') url = loginUrl()
    else if (domain === 'farm') url = farmUrl()
    else if (domain === 'consulting') url = consultingUrl()
    else if (domain === 'market') url = marketUrl()
    else if (domain === 'nuxt') url = geoUrl();
    if (url) {
        Cookies.set(LAST_ORIGIN_HEADER, domain, {expires: 1800000, domain: mainDomain() })
        window.open(`${url}/${path ? path : ''}`, "_self")
    }
}

export const setLastLanguage = (val: string) => Cookies.set(LAST_LANGUAGE_HAEDER, val, { domain: mainDomain() })

export const getLastLanguage = () => {
    const language = Cookies.get(LAST_LANGUAGE_HAEDER, { domain: mainDomain() })
    if (language === undefined) {
        const windowNavigator: any = window.navigator
        const userLang: any = windowNavigator.language || windowNavigator.userLanguage
        return userLang.substring(0, 2)
    } else return language
}