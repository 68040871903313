export default {
    title: "Dispatcher",
    manage: "Manage Notifications",
    permissions: {
        alarms: {
            title: "Оповещения",
            workspace_change: "Приглашение, вход или выход с проекта",
            notice_mention: "Отметка в заметках",
            notice_reply: "Ответ на заметку",
            response_new_image: "Новые снимки с проблемными зонами",
            croprotation_empty: "Незаполненная история полей",
            task_add: "Добавление в задачах",
            tractor_motion: "Начало и конец работы техники",
            payment_done: "Оплата подписки"
        },
        warnings: {
            title: "Тревоги",
            notice_field_not_visit: "Неосмотренные поля",
            weather_draught: "Отсутствие осадков",
            response_dangerous_zone: "Новые снимки с проблемными зонами",
            task_incomplete: "Невыполненные задачи",
            telematic_max_speed: "Превышение скорости",
            telematic_out_of_field: "Работа за границей полей",
            tractor_stop: "Простой техники",
            tractor_breakdown: "Поломка техники",
            tractor_expired_maintenace: "Просроченное обслуживание техники",
            telematic_fuel: "Телематика - топливо (слив, переполнение бака)",
            telematic_no_info: "Телематика - отсутствие данных(проблема с сетью, отключение, падение напряжение в датчике)"
        }
    },
    notif_categories: {
        all: "All",
        notification: "Notification",
        alarm: "Alarm",
        spam: "Spam"
    },
    msg_categories: {
        all: "All",
        starred: "Starred",
        read: "Read",
        not_read: "Unread",
        not_marked: "Not marked"
    },
    msg_actions: {
        spam: "To spam",
        share: "Share",
        print: "Print",
        to_notes: "Go to note",
        to_projects: "Go to projects",
        to_farm: "Go to the farm"
    }
}